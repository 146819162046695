import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';
import { generateVRID, generateUUID } from 'src/utils';
import { BoundingBoxConfig } from 'src/types';
import type { GetManagementStoresData } from 'src/utils';

export const ZONE_SCHEMA = z.object({
  uuid: z.string(),
  detection_zone: z.string(),
  dzone_direction: z.number(),
  detection_area: z.boolean(),
  direction_angle: z.number(),
  direction_angle_threshold: z.number(),
  id: z.string().nullable().optional(),
  epos_filter_field: z.string().nullable().optional(),
  epos_filter: z.string().nullable().optional(),
  category: z.string().optional(),
  checkout_id: z.string().nullable().optional(),
  epos_filters: z
    .array(
      z.object({
        filter_name: z.string(),
        filter_value: z.string(),
        not_flag: z.boolean(),
      })
    )
    .nullable()
    .optional(),
});

const GET_DETECTION_ZONE_SCHEMA = z.object({
  zones: z.array(ZONE_SCHEMA),
  message: z.string().optional().or(z.null()),
});

type ZS = z.infer<typeof ZONE_SCHEMA>;
export interface ZoneSchema extends ZS {
  display_id?: string;
}

export type GetDetectionZoneSchema = z.infer<typeof GET_DETECTION_ZONE_SCHEMA>;

export const convertApiDetectionZoneToBoundingBoxConfig = (zone: ZoneSchema) => {
  const cords = zone.detection_zone.split('-').map((cord) => Number(cord));

  const id = generateVRID();

  // convert to bounding box object
  return {
    ...zone,
    // backward compatibility for dzone_direction
    dzone_direction: (zone as unknown as { direction: number }).direction || zone.dzone_direction,
    category: zone.category ?? '',
    x: cords[0],
    y: cords[1],
    width: cords[2] - cords[0],
    height: cords[3] - cords[1],
    id: zone.id ?? id,
    name: zone.id ?? id,
    points: [],
    isDrawing: false,
    min_time: 0,
    isPrevious: true,
    epos_filters: (zone.epos_filters ?? []).map((filter) => ({
      ...filter,
      id: generateUUID(),
    })),
    checkout_id: undefined,
  };
};

export const getDetectionZonePromise = async (
  display_id: string
): Promise<Array<Omit<BoundingBoxConfig, 'stageWidth' | 'stageHeight' | 'fill'>>> => {
  const response = await ky
    .post(`${ENV.FRONTEND_API_URL}/get_detection_zone`, { body: `{"display_id":"${display_id}"}` })
    .json();

  const result = GET_DETECTION_ZONE_SCHEMA.safeParse(response);

  if (!result.success) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isEmpty = (response as GetDetectionZoneSchema)?.message?.toLowerCase?.() === 'no detection zones.';

    if (isEmpty) return [];

    throw new Error('Invalid response');
  }

  return result.data.zones.map((zone) => convertApiDetectionZoneToBoundingBoxConfig({ ...zone, display_id }));
};

export const getInStoreBoundariesByDeviceIds = async (devices: GetManagementStoresData['raw'][number]['devices']) => {
  const deviceIds = devices.map((device) => device.uuid);
  const res = await Promise.all(deviceIds.map((deviceId) => getDetectionZonePromise(deviceId)));

  const resWithDeviceName = res
    .flat()
    .map((deviceZones) => {
      const deviceName = devices.find((device) => device.uuid === deviceZones.display_id)?.device_key || deviceZones.id;
      return {
        ...deviceZones,
        deviceName,
      };
    })
    // means it's a boundary
    .filter((el) => !el.detection_area);

  const parsedData = resWithDeviceName.map((zone) => ({
    value:
      (
        zone as unknown as {
          uuid: string;
        }
      ).uuid || zone.id,
    label: zone.deviceName,
    ...zone,
  }));

  return {
    raw: parsedData,
    asOptions: parsedData.map((zone) => ({ value: zone.value, label: zone.label })),
  };
};
